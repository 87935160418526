export enum BaronaCompany {
  Logistiikka = "02",
  Projektit = "04",
  Teollisuus = "06",
  Logistiikkaratkaisut = "07",
  Solutions = "12",
  IT = "16",
  CustomerCare = "25",
  BaronaICTServicesOy = "29",
  Momentous = "30",
  Saranen = "32",
  SalesServices = "51",
  SCCEast = "53",
  SCCWest = "56",
  SCCNorth = "57",
  SalesRetail = "58",
  SalesSouth = "59",
  SalesFashion = "60",
  SalesNorth = "61",
  SalesWest = "62",
  SCCNorthWest = "63",
  SalesSouthWest = "64",
  SalesEast = "66",
  TekniikkaJaAsennus = "72",
  Skillcode = "73",
  Sales = "81",
  HR = "82",
  Finance = "83",
  CustomerServices = "84",
  Logistiikkapalvelut = "87",
  Varastopalvelut = "88",
  TalentGate = "202",
  Horeca = "206",
  Kauppa = "210",
  Production = "214",
  Flex = "222",
  Luova = "227",
  LounaisSuomi = "228",
  SisaSuomi = "239",
  Pohjanmaa = "246",
  ItaSuomi = "248",
  Lapland = "254",
  Bravedo = "260",
  Global = "311",
  Frontliners = "332",
  Fodbar = "314",
  UgglaEngineering = "330",
  FodbarHenkilosto = "333",
  CostaCommerce = "334",
  Sevendos = "335",
  LogistiikkaKoulutuspalvelut = "336",
}
